.search-results {
  border: 3px solid black;
  background-color: white;
  color: white;
  border-radius: 10px;
  position: absolute;
  margin-top: 20px;
  overflow-y: scroll;
  max-height: 600px;
  z-index: 1000;
  padding: 10px;
  background-color: black;
  display: none;
  .search-item {
    font-size: 20px;
    font-weight: medium;
    color: white !important;
    text-decoration: none;
  }
  .search-item:hover {
    background-color: gray;
  }
}
.display {
  display: block !important;
}
