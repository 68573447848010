.add {
  .form-control {
    background: var(--b-primary);
    border-color: var(--border);
  }

  .form-container {
    form {
      .form-group {
        display: inline-block;
      }
      div:nth-child(1) {
        .full-name {
          width: 40%;
          padding-right: 40px;
        }
        .category {
          width: 20%;
        }
      }
      div:nth-child(2),
      div:nth-child(3),
      div:nth-child(4) {
        .form-group {
          width: 20%;
          padding-right: 40px;
        }
      }
      div:nth-child(5) {
        .form-group {
          width: 70%;
          padding-right: 40px;
        }
      }

      button {
        margin-left: 40px;
        margin-bottom: 2rem;
        width: 100px;
        height: 30px;
        font-weight: bold;
        background: var(--b-primary);
        border-color: var(--b-primary);
      }
      button:hover {
        background-color: white;
        color: var(--b-primary);
        border-color: var(--border);
      }

      button:active {
        background-color: var(--bg-primary) !important;
        border-color: var(--border) !important;
        box-shadow: none !important;
      }
      button:focus {
        color: white;
        background: var(--b-primary);
        border-color: var(--border) !important;
        box-shadow: none;
      }
    }
  }
}
