.dao {
  background: linear-gradient(
    184.17deg,
    #402c55 3.4%,
    rgba(39, 40, 60, 0) 75.89%
  );
  border: 1px solid #31324b;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  .full-name {
    font-size: 40px;
  }
  .card {
    background: rgba(196, 196, 196, 0.09);
    border-radius: 20px;
    background: var(--b-primary);
  }

  button {
    font-size: 14px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 8px 12px;
    font-size: 0.825rem;
    font-weight: 600;
    cursor: pointer;
    border: 1px solid transparent;
    outline: none;
    min-width: fit-content;
    border-radius: 12px;
    white-space: nowrap;
  }
  .details {
    margin-top: 30px;

    display: flex;
    justify-content: space-between;

    .description {
      width: 48%;
      .card-text {
        font-size: 14px;
      }
    }
    .links {
      margin-bottom: 10px;
    }
  }

  .token-info {
    margin-top: 30px;
    .title {
      margin-bottom: 10px;
      font-size: 26px;
    }
    .card {
      margin-top: 10px;
      .card-body {
        padding-top: 10px;
      }
    }
    .token-header {
      color: white;
      font-weight: medium;
      font-size: 13px;
    }
    .token-header,
    .token {
      display: flex;
      div:nth-child(1) {
        width: 10%;
      }
      div:nth-child(2) {
        width: 20%;
      }
      div:nth-child(3) {
        width: 15%;
      }
      div:nth-child(4) {
        width: 15%;
      }
    }

    .token {
      display: flex;
      border-radius: 10px;
      text-decoration: none;
      color: white;
      div {
        div > div {
          width: 100%;
        }
      }
    }
  }
}
