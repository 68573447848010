.searchBar {
  width: 50%;
  padding: 15px;
  height: 10px;
  border-radius: 20px;
  background: var(--b-primary);
  border-color: var(--border);
}

.search-container {
  display: flex;
  justify-content: space-between;
}

.form-control:focus {
  color: white;
  background: var(--b-primary);
  border-color: var(--border);
  outline: 0;
  box-shadow: none;
}
.serachResults {
  display: inline;
}

.menu {
  button {
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    font-weight: bold;
    background: var(--b-primary);
    border-color: var(--border);
  }
  button:hover {
    background-color: white !important;
    color: var(--bg-primary) !important;
    background: var(--b-primary);
    border-color: var(--border);
  }

  button:active {
    background-color: white !important;
    color: var(--bg-primary) !important;
    background: var(--b-primary);
    border-color: var(--border);
    border-color: var(--border) !important;
    box-shadow: none !important;
  }
  button:focus {
    color: white;
    background: var(--b-primary);
    border-color: var(--border) !important;
    box-shadow: none;
  }
}
.search-results {
  border: 3px solid black;
  background-color: white;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 15px;
  position: absolute;
  margin-top: 20px;
  overflow-y: scroll;
  width: 350px;
  max-height: 600px;
  z-index: 1000;
  padding: 10px;
  background-color: black;
  display: none;
}
.display {
  display: block !important;
}
